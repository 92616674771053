import React from 'react';
import { BrowserRouter, Route, Switch, RouteProps } from 'react-router-dom';
import './App.css';
import {
  CssBaseline,
  createMuiTheme,
  MuiThemeProvider,
  useMediaQuery,
} from '@material-ui/core';
import { LoginForm } from 'src/components/login-form';
import { PrivateRoute } from 'src/components/private-route';
import { routes } from 'src/routes';
import { green } from '@material-ui/core/colors';
import { MainLayout } from 'src/components/main-layout';

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const theme = React.useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: 'dark',
          primary: green,
        },
      }),
    [prefersDarkMode],
  );
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <div>
          <CssBaseline />
          <Switch>
            <Route
              exact
              path="/login"
              component={(props: RouteProps) => (
                <LoginForm
                  redirect={
                    // @ts-ignore
                    props?.location?.state?.from
                  }
                />
              )}
            />

            {routes.map(route => (
              <PrivateRoute
                key={route.path}
                exact
                path={route.path}
                roles={route.roles}
                component={() => <MainLayout>{route.component}</MainLayout>}
              />
            ))}
          </Switch>
        </div>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default App;
