import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Divider,
  Grid,
  Paper,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { StoWorkSelect } from 'src/features/sto-work-select';
import { STOWorkPrice } from 'src/shared/api';
import { useGetDiagnostic } from 'src/entities/diagnostic';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { VehicleSelect } from 'src/components/vehicle-select';
import { Customer } from 'src/components/Customer';
import { CreateDiagnosticElement } from 'src/entities/diagnostic';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'DiagnosticPage' },
);

export const DiagnosticPage: React.FunctionComponent<typeProps> = ({}) => {
  const classes = useStyles({});

  const { diagnosticId } = useParams<{ diagnosticId: string }>();

  const [STOWorkPrice, setStoWorkPrice] = useState<null | STOWorkPrice>(null);

  const { data: diagnostic, refetch } = useGetDiagnostic({
    variables: { id: diagnosticId },
  });

  if (!diagnostic) return null;

  return (
    <>
      <Paper>
        <Box p={2}>
          <Grid className={classes.root} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">
                Дикнгостика #{diagnosticId} от{' '}
                {diagnostic &&
                  format(new Date(diagnostic.createdAt), 'yyyy-MM-dd HH:mm:ss')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Customer customer={diagnostic.customer} />
              <Box mt={2}>
                <VehicleSelect
                  onChange={() => null}
                  customer={diagnostic.customer}
                  vehicle={diagnostic.vehicle}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box mt={2}>
        <Paper>
          <Box p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Box flexGrow={1}>
                    <StoWorkSelect
                      onChange={setStoWorkPrice}
                      value={STOWorkPrice}
                      vehicle={diagnostic?.vehicle}
                    />
                  </Box>
                  <Box ml={2}>
                    <CreateDiagnosticElement
                      diagnostic={diagnostic}
                      stoWorkPrice={STOWorkPrice}
                      onSave={refetch}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {diagnostic.elements.map(el => (
                  <Grid container spacing={2} key={el.id}>
                    <Grid item xs={4}>
                      <StoWorkSelect onChange={() => {}} />
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        label="Комментарий"
                        value={el.comment || ''}
                        fullWidth
                        multiline
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Box display="flex" alignItems="center">
                        <TextField
                          value={el.price / 100}
                          fullWidth
                          variant="outlined"
                        />
                        <Box mx={2}>*</Box>
                        <TextField
                          value={el.quantity}
                          fullWidth
                          variant="outlined"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </>
  );
};
