import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getProfile } from 'src/api/userApi';
import { UserRole } from 'src/shared/api';

type typeProps = {
  component: any;
  exact?: boolean;
  path: string;
  roles?: UserRole[];
};

export const PrivateRoute: React.FunctionComponent<typeProps> = ({
  component: Component,
  roles,
  ...rest
}) => {
  const [state, setState] = useState<{ loading: boolean; profile: any }>({
    loading: true,
    profile: null,
  });

  useEffect(() => {
    getProfile().then(
      res => setState({ loading: false, profile: res.data }),
      () => setState(s => ({ ...s, loading: false })),
    );
  }, []);

  return (
    <Route
      {...rest}
      render={props => {
        if (state.loading && !state.profile) {
          return 'Loading...';
        }

        if (!state.loading && !state.profile) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }

        if (
          !state.loading &&
          state.profile?.roles.includes(UserRole.ROLE_ADMIN)
        ) {
          return <Component {...props} />;
        }

        if (
          !state.loading &&
          roles?.some(role =>
            state.profile?.roles.includes(role),
          )
        ) {
          return <Component {...props} />;
        }

        return <div>Hola!</div>
      }}
    />
  );
};
