import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Theme,
} from '@material-ui/core';
import { useGetVehicles } from 'src/api/vehicleApi';
import { typeVehicle } from 'src/types';
import { Autocomplete } from 'src/components/common/autocomplete';
import { Link } from '@material-ui/icons';
import { VehicleCreate } from '../vehicle-create';
import { Customer } from 'src/shared/api';

type typeProps = {
  onChange: (vehicle: typeVehicle | null) => any;
  vehicle?: typeVehicle | null;
  customer?: Customer | null;
};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      labelSub: {
        color: theme.palette.grey[400],
        fontSize: theme.typography.pxToRem(14),
      },
    }),
  { name: 'VehicleSelect' },
);

export const VehicleSelect: React.FunctionComponent<typeProps> = ({
  onChange,
  vehicle,
  customer,
}) => {
  const classes = useStyles({});

  const [value, setValue] = useState<typeVehicle | null>(null);

  const [isAllVehicles, setAllVehicles] = useState(false);

  const variables = { customerId: isAllVehicles ? null : customer?.id };

  const { data, loading, refetch } = useGetVehicles({ variables });

  useEffect(() => {
    const valueFromArray =
      data && vehicle
        ? (data?.data?.find(item => item.id === vehicle.id) as typeVehicle)
        : null;
    setValue(valueFromArray);
  }, [vehicle, data]);

  const handleOnChange = (next: typeVehicle | null) => {
    setValue(next);
    if (onChange) {
      onChange(next);
    }
  };

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Autocomplete
          disableClearable
          fullWidth
          loading={loading}
          value={value}
          onChange={(e, value) => handleOnChange(value)}
          options={
            data?.data.sort((a, b) =>
              a?.name &&
              b.name &&
              a?.id &&
              b.id &&
              `${a?.name}${a?.id}` > `${b?.name}${b?.id}`
                ? 1
                : -1,
            ) || []
          }
          getOptionLabel={(option: typeVehicle) => option?.name || ''}
          renderOption={(option: typeVehicle) => (
            <div>
              <div>{option?.name}</div>
              <div className={classes.labelSub}>
                ID: {option?.id} Дата: {option?.createdAt?.substr(0, 10)}{' '}
                {option?.frame ? `VIN: ${option?.frame}` : ''}
              </div>
            </div>
          )}
          label="Автомобиль"
          actions={
            vehicle || value
              ? [
                  <IconButton
                    size="small"
                    target="_blank"
                    href={`/vehicles/${value?.id || vehicle?.id}`}
                    title="Открыть автомобиль"
                    onClick={e => e.stopPropagation()}
                  >
                    <Link />
                  </IconButton>,
                ]
              : []
          }
        />
        <Box ml={2}>
          <VehicleCreate
            onCreate={() => refetch()}
            customer={isAllVehicles ? null : customer}
          />
        </Box>
      </Box>
      {customer && (
        <Box mt={2} display="flex" alignItems="center">
          <FormControlLabel
            onChange={() => setAllVehicles(!isAllVehicles)}
            control={<Checkbox checked={isAllVehicles} />}
            label="Все автомобили"
          />
        </Box>
      )}
    </Box>
  );
};
