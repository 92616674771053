import { useRequest } from 'src/hooks/useRequest';
import { request } from 'src/services/request';
import { Contractor, Customer, Diagnostics, Vehicle } from 'src/shared/api';

export function createDiagnostic(value: {
  contractor: Pick<Contractor, 'id'> | null;
  customer: Pick<Customer, 'id'> | null;
  vehicle: Pick<Vehicle, 'id'> | null;
}) {
  return request.post(`/diagnostics`, value).then(data => data.data);
}

export function useGetDiagnostic({
  variables,
  skip,
}: {
  variables: { id: number | string };
  skip?: boolean;
}) {
  return useRequest<Diagnostics, typeof variables>({
    url: `/diagnostics/${variables.id}`,
    method: 'get',
    skip,
  });
}

export function addElementToDiagnostic(
  diagnosticId: number | string,
  value: {},
) {
  return request
    .post(`/diagnostics/${diagnosticId}/elements`, value)
    .then(data => data.data);
}
