import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Box, Paper, Link, Button, Checkbox } from '@material-ui/core';
import { useBillingAccounts, useGetBillingSummary } from 'src/api/billingApi';
import { Table } from '../common/table';
import { typeBillingAccount } from 'src/types';
import { BillingAccountCreate } from '../BillingAccountCreate';
import { BillingJournalCreate } from '../BillingJournalCreate';
import { ContractorLabel } from '../ContractorLabel';
import { sum } from 'lodash';

type typeProps = {};

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
      actions: {
        '& button': {
          marginLeft: theme.spacing(1),
        },
      },
    }),
  { name: 'AccountsPage' },
);

export const AccountsPage: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});
  const [filter, setFilter] = useState({
    closed: false,
    empty: false,
    RUB: true,
    USD: false,
    JPY: false,
    CNY: false,
  });

  const [sumAccMap, setSumAccMap] = useState<{
    [key: number]: typeBillingAccount | null;
  }>({});

  const { data, loading, refetch, cancel } = useBillingAccounts();
  const { data: summary } = useGetBillingSummary();

  const compatyTotal = (summary?.companyBalance &&
    Object.keys(summary?.companyBalance)?.reduce(
      (prev, curr) => ({
        RUB: (summary?.companyBalance[curr].RUB || 0) + prev.RUB,
        USD: (summary?.companyBalance[curr].USD || 0) + prev.USD,
        JPY: (summary?.companyBalance[curr].JPY || 0) + prev.JPY,
        CNY: (summary?.companyBalance[curr].CNY || 0) + prev.CNY,
      }),
      {
        RUB: 0,
        USD: 0,
        JPY: 0,
        CNY: 0,
      },
    )) || {
    RUB: 0,
    USD: 0,
    JPY: 0,
    CNY: 0,
  };

  const byContractors =
    data?.data &&
    Object.entries<typeBillingAccount[]>(
      data?.data
        ?.filter(account =>
          [
            Boolean(account?.contractor),
            filter.closed ? true : !account?.isClosed,
            filter.empty ? true : Boolean(account?.balance),
            (filter.RUB && account?.currency === 'RUB') ||
              (filter.USD && account?.currency === 'USD') ||
              (filter.JPY && account?.currency === 'JPY') ||
              (filter.CNY && account?.currency === 'CNY'),
          ].every(value => value),
        )
        ?.reduce(
          (prev: any, account) =>
            prev[account?.contractor?.alias as string]
              ? {
                  ...prev,
                  [account?.contractor?.alias as string]: [
                    ...prev[account?.contractor?.alias as string],
                    account,
                  ],
                }
              : { ...prev, [account?.contractor?.alias as string]: [account] },
          {},
        ),
    );
  return (
    <div className={classes.root}>
      <Paper>
        <Box>
          <Box p={2}>
            Баланс системы:{' '}
            {summary?.globalBalance ? summary?.globalBalance / 100 : 0}
          </Box>
          <Box display="flex" flexWrap="wrap">
            <Box px={2} py={1}>
              TOTAL: {(compatyTotal.RUB / 100).toLocaleString('ru-RU')} RUB
              {' | '}
              {(compatyTotal.USD / 100).toLocaleString('ru-RU')} USD
              {' | '}
              {(compatyTotal.JPY / 100).toLocaleString('ru-RU')} JPY
              {' | '}
              {(compatyTotal.CNY / 100).toLocaleString('ru-RU')} CNY
            </Box>
          </Box>
          {summary?.companyBalance &&
            Object.keys(summary?.companyBalance)?.map(key => {
              if (Array.isArray(summary?.companyBalance[key])) return null;

              return (
                <Box display="flex" flexWrap="wrap">
                  <Box px={2} py={1}>
                    {key.toUpperCase()}:{' '}
                    {(summary?.companyBalance[key]
                      ? (summary?.companyBalance[key].RUB || 0) / 100
                      : 0
                    ).toLocaleString('ru-RU')}{' '}
                    RUB
                    {' | '}
                    {(summary?.companyBalance
                      ? (summary?.companyBalance[key].USD || 0) / 100
                      : 0
                    ).toLocaleString('ru-RU')}{' '}
                    USD
                    {' | '}
                    {(summary?.companyBalance
                      ? (summary?.companyBalance[key].JPY || 0) / 100
                      : 0
                    ).toLocaleString('ru-RU')}{' '}
                    JPY
                    {' | '}
                    {(summary?.companyBalance
                      ? (summary?.companyBalance[key].CNY || 0) / 100
                      : 0
                    ).toLocaleString('ru-RU')}{' '}
                    CNY
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Paper>
      <Box
        mt={2}
        className={classes.actions}
        display="flex"
        justifyContent="flex-end"
      >
        <Button
          variant={filter.empty ? 'contained' : 'outlined'}
          color="primary"
          size="small"
          onClick={() => setFilter({ ...filter, empty: !filter.empty })}
        >
          Пустой
        </Button>
        <Button
          variant={filter.closed ? 'contained' : 'outlined'}
          color="primary"
          size="small"
          onClick={() => setFilter({ ...filter, closed: !filter.closed })}
        >
          Закрыт
        </Button>
        <Button
          variant={filter.RUB ? 'contained' : 'outlined'}
          color="primary"
          size="small"
          onClick={() => setFilter({ ...filter, RUB: !filter.RUB })}
        >
          RUB
        </Button>
        <Button
          variant={filter.USD ? 'contained' : 'outlined'}
          color="primary"
          size="small"
          onClick={() => setFilter({ ...filter, USD: !filter.USD })}
        >
          USD
        </Button>
        <Button
          variant={filter.JPY ? 'contained' : 'outlined'}
          color="primary"
          size="small"
          onClick={() => setFilter({ ...filter, JPY: !filter.JPY })}
        >
          JPY
        </Button>
        <Button
          variant={filter.CNY ? 'contained' : 'outlined'}
          color="primary"
          size="small"
          onClick={() => setFilter({ ...filter, CNY: !filter.CNY })}
        >
          CNY
        </Button>
      </Box>
      {byContractors?.map(item => {
        const summary = item[1]?.reduce(
          (prev: { [key: string]: number }, account: typeBillingAccount) =>
            prev[account?.currency as string]
              ? {
                  ...prev,
                  [account.currency as string]:
                    prev[account.currency as string] + (account?.balance || 0),
                }
              : {
                  ...prev,
                  [account.currency as string]: account?.balance || 0,
                },

          {},
        );
        return (
          <Box mt={2} key={item[0]}>
            <Paper>
              <Table
                size="small"
                title={item[1]?.[0]?.contractor?.name}
                data={item[1] || []}
                totalCount={data?.totalCount}
                headers={[
                  {
                    title: 'id',
                    extract: (item: typeBillingAccount) => (
                      <Link target="_blank" href={`/accounts/${item?.id}`}>
                        {item.id}
                      </Link>
                    ),
                    component: 'th',
                    scope: 'row',
                  },
                  {
                    title: 'Название',
                    extract: (item: typeBillingAccount) => (
                      <>
                        {item.name}{' '}
                        <ContractorLabel contractor={item?.contractor} />
                      </>
                    ),
                  },
                  {
                    title: 'Действия',
                    extract: (item: typeBillingAccount) => (
                      <Box display="flex">
                        <BillingJournalCreate
                          onCreate={() => refetch()}
                          to={item}
                        />
                        <Box ml={1}>
                          <BillingJournalCreate
                            add={false}
                            onCreate={() => refetch()}
                            from={item}
                          />
                        </Box>
                      </Box>
                    ),
                  },
                  {
                    summary:
                      summary &&
                      Object.entries(summary)
                        ?.map(
                          item =>
                            `${(item[1] / 100).toLocaleString('ru-RU')} ${
                              item[0]
                            }`,
                        )
                        ?.join(' | '),
                    title: 'Баланс',
                    align: 'right',
                    extract: (item: typeBillingAccount) =>
                      (item.balance ? item.balance / 100 : 0).toLocaleString(
                        'ru-RU',
                      ),
                  },
                  {
                    title: 'Валюта',
                    align: 'right',
                    extract: (item: typeBillingAccount) => item.currency,
                  },
                  {
                    title: 'Закрыт',
                    align: 'right',
                    extract: (item: typeBillingAccount) => (
                      <Checkbox checked={item.isClosed} disabled />
                    ),
                  },
                  {
                    title: 'Сложить',
                    align: 'right',
                    extract: (item: typeBillingAccount) => (
                      <Checkbox
                        checked={Boolean(sumAccMap[item.id as number])}
                        onClick={() =>
                          setSumAccMap({
                            ...sumAccMap,
                            [item.id as number]: sumAccMap[item.id as number]
                              ? null
                              : item,
                          })
                        }
                      />
                    ),
                  },
                ]}
                loading={loading}
                onCancel={cancel}
                withPagination={false}
                withSummary
                onFetch={refetch}
                limit={0}
                offset={0}
              />
            </Paper>
          </Box>
        );
      })}
      <Box mt={2}>
        <Paper>
          <Box p={2} display="flex" justifyContent="flex-end">
            Сумма выбранных:{' '}
            {(
              Object.values(sumAccMap).reduce(
                (prev, curr) => (curr?.balance || 0) + prev,
                0,
              ) / 100
            ).toLocaleString('ru-RU')}
          </Box>
        </Paper>
      </Box>
      <Box mt={2} display="flex" justifyContent="flex-end">
        <BillingAccountCreate onCreate={() => refetch()} />
      </Box>
    </div>
  );
};
