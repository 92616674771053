import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, Theme } from '@material-ui/core';
import { Diagnostics, STOWorkPrice } from 'src/shared/api';
import { addElementToDiagnostic } from '../model';

interface typeProps {
  diagnostic: Diagnostics;
  stoWorkPrice?: STOWorkPrice | null;
  onSave?: () => any;
}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'CreateDiagnosticElement' },
);

export const CreateDiagnosticElement: React.FunctionComponent<typeProps> = ({
  diagnostic,
  stoWorkPrice,
  onSave,
}) => {
  const classes = useStyles({});

  const handleOnClick = () =>
    addElementToDiagnostic(diagnostic.id, {
      work: stoWorkPrice?.stoWork,
      price: stoWorkPrice?.price ? stoWorkPrice?.price / 100 : 0,
    }).then(() => {
      if (onSave) onSave();
    });

  return (
    <Button
      className={classes.root}
      onClick={handleOnClick}
      color="primary"
      variant={stoWorkPrice ? 'contained' : 'outlined'}
    >
      Добавить в документ
    </Button>
  );
};
