import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState, FC, useEffect } from 'react';
import { useGetOrder } from 'src/api/ordersApi';
import { registerCheck, useGetCashRegister } from 'src/entities/kkm';
import {
  CashRegisterSessionState,
  Order,
  TaxVariant,
  TypeCheck,
} from 'src/shared/api';

const hintsMap: { [key: string]: string } = {
  IsFiscalCheck: 'Это фискальный или не фискальный чек',
  TypeCheck: 'Тег ОФД 1054',
  NotPrint:
    'Некоторые ККТ всегда печатают отчеты. Некоторые ККТ всегда печатают чеки если ККТ не зарегистрирована на "Интернет продажи". Некоторые ККТ всегда печатают чеки если в данных чека нет телефона или email-а клиента. А у некоторых это еще зависит и от версии прошивки.',
  CashierName: 'Тег ОФД 1021',
  CashierVATIN: 'Тег ОФД 1203',
  ClientAddress:
    'Тег ОФД 1008. Телефон или е-Майл покупателя. Если чек не печатается (NotPrint = true) то указывать обязательно. Формат: Телефон +{Ц} или Email {С}@{C}',
  ClientInfo:
    'Наименование организации или фамилия, имя, отчество (при наличии), серия и номер паспорта покупателя(клиента). Тег 1227. Только с использованием наличных / электронных денежных средств и при выплате выигрыша, получении страховой премии или при страховой выплате.',
  ClientINN:
    'Тег 1228. Только с использованием наличных / электронных денежных средств и при выплате выигрыша, получении страховой премии или при страховой выплате.',
  SenderEmail:
    'Тег ОФД 1117 (если задан при регистрации можно не указывать) Формат: Email {С}@{C}',
  AddressSettle:
    'Тег ОФД 1009 (если не задано - берется из регистрационных данных ККТ)',
  PlaceMarket:
    'Тег ОФД 1187 (если не задано - берется из регистрационных данных ККТ)',
  TaxVariant:
    'Комбинация разных СНО не возможна. Надо указывать если ККМ настроена на несколько систем СНО',
  CorrectionType: 'Тег 1173, Только для чеков коррекции!',
  CorrectionBaseDate:
    'Дата документа основания для коррекции, Тег ОФД 1178, Только для чеков коррекции!',
  CorrectionBaseNumber:
    'Номер документа основания для коррекции, Тег ОФД 1179, Только для чеков коррекции!',
  PayByProcessing:
    'Если надо одновременно автоматически провести транзакцию через эквайринг. Эквайринг будет задействован если: 1. чек фискальный, 2. оплата по "ElectronicPayment" не равна 0, 3. PayByProcessing = true. Использовать эквайринг: Null - из настроек на сервере, false - не будет, true - будет',

  NumDeviceByProcessing:
    'Номер устройства для эквайринга - Null - из настроек на сервере, 0 - любое, число - номер конкретного устройства',
  ReceiptNumber: 'Номер чека для эквайринга',
  PrintSlipAfterCheck: 'Печатать Слип-чек после чека (а не в чеке)',
  PrintSlipForCashier:
    'Печатать Слип-чек дополнительно для кассира (основной слип-чек уже будет печататся в составе чека)',
  RRNCode:
    'RRNCode из операции эквайринга. Только для отмены оплаты! Для Оплаты или возврата оплаты не заполнять!. Если это чек возврата то возможны два поля для отмены транзакции (если не указано то по эквайрингу будет не отмена а возврат оплаты)',
  AuthorizationCode:
    'AuthorizationCode из операции эквайринга. Только для отмены оплаты! Для Оплаты или возврата оплаты не заполнять!',
};

interface BaseReceiptProp {
  key: string;
  name: string;
}

interface ReceiptPropBool extends BaseReceiptProp {
  type: ReceiptPropType.boolean;
}

interface ReceiptPropNumber extends BaseReceiptProp {
  type: ReceiptPropType.number;
}

interface ReceiptPropString extends BaseReceiptProp {
  type: ReceiptPropType.string;
}

interface ReceiptPropList extends BaseReceiptProp {
  type: ReceiptPropType.list;
  values: Array<[string, any]>;
}

type ReceiptProp =
  | ReceiptPropBool
  | ReceiptPropNumber
  | ReceiptPropString
  | ReceiptPropList;

enum ReceiptPropType {
  'boolean',
  'number',
  'string',
  'list',
}

const ReceiptProp: FC<{
  prop: ReceiptProp;
  onChange: (value: any) => any;
  value: any;
}> = ({ prop, onChange, value }) => {
  if (prop.type === ReceiptPropType.boolean) {
    return (
      <FormControlLabel
        control={<Checkbox checked={value} />}
        onChange={() => onChange({ [prop.key]: !value })}
        label={prop.name}
      />
    );
  }

  if (prop.type === ReceiptPropType.number) {
    return (
      <TextField
        fullWidth
        label={prop.name}
        variant="outlined"
        value={value}
        type="number"
        onChange={e => onChange({ [prop.key]: parseInt(e.target.value, 10) })}
      />
    );
  }

  if (prop.type === ReceiptPropType.string) {
    return (
      <TextField
        fullWidth
        label={prop.name}
        variant="outlined"
        value={value}
        onChange={e => onChange({ [prop.key]: e.target.value })}
      />
    );
  }

  if (prop.type === ReceiptPropType.list) {
    return (
      <FormControl variant="outlined">
        <InputLabel id={prop.key}>{prop.name}</InputLabel>
        <Select
          fullWidth
          label={prop.name}
          labelId={prop.key}
          onChange={e =>
            onChange({
              [prop.key]: e.target.value as number,
            })
          }
          value={value}
        >
          {prop?.values.map(item => (
            <MenuItem key={item[1]} value={item[1]}>
              {item[0]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
  return <div />;
};

const ReceiptFormProps: ReceiptProp[] = [
  {
    key: 'IsFiscalCheck',
    name: 'Фискальный чек',
    type: ReceiptPropType.boolean,
  },
  {
    key: 'TypeCheck',
    name: 'Тип чека',
    type: ReceiptPropType.list,
    values: Object.entries(TypeCheck).filter(item => Number.isInteger(item[1])),
  },
  {
    key: 'NotPrint',
    name: 'Не печатать чек',
    type: ReceiptPropType.boolean,
  },
  {
    key: 'NumberCopies',
    name: 'Количество копий',
    type: ReceiptPropType.number,
  },
  {
    key: 'CashierName',
    name: 'Продавец',
    type: ReceiptPropType.string,
  },
  {
    key: 'CashierVATIN',
    name: 'ИНН продавца',
    type: ReceiptPropType.string,
  },
  {
    key: 'ClientAddress',
    name: 'Телефон или е-Майл покупателя',
    type: ReceiptPropType.string,
  },
  {
    key: 'ClientInfo',
    name: 'Покупатель (клиент)',
    type: ReceiptPropType.string,
  },
  {
    key: 'ClientINN',
    name: 'ИНН Организации или покупателя (клиента)',
    type: ReceiptPropType.string,
  },
  {
    key: 'SenderEmail',
    name: 'Aдрес электронной почты отправителя чека',
    type: ReceiptPropType.string,
  },
  {
    key: 'AddressSettle',
    name: 'Адрес расчетов',
    type: ReceiptPropType.string,
  },
  {
    key: 'PlaceMarket',
    name: 'Место расчетов',
    type: ReceiptPropType.string,
  },
  {
    key: 'TaxVariant',
    name: 'Система налогообложения (СНО)',
    type: ReceiptPropType.list,
    values: Object.entries(TaxVariant).filter(item =>
      Number.isInteger(item[1]),
    ),
  },
  {
    key: 'PayByProcessing',
    name: 'Автоматически провести транзакцию через эквайринг',
    type: ReceiptPropType.boolean,
  },
  {
    key: 'NumDeviceByProcessing',
    name: 'Номер устройства для эквайринга',
    type: ReceiptPropType.number,
  },
  {
    key: 'ReceiptNumber',
    name: 'Номер чека для эквайринга',
    type: ReceiptPropType.string,
  },
  {
    key: 'PrintSlipAfterCheck',
    name: 'Печатать Слип-чек после чека',
    type: ReceiptPropType.boolean,
  },
  {
    key: 'PrintSlipForCashier',
    name: 'Печатать Слип-чек дополнительно для кассира',
    type: ReceiptPropType.boolean,
  },
];

interface ReceiptElement {
  Register: {
    Name: string;
    Quantity: number;
    Price: number;
    Amount: number;
    Department: number;
    Tax: number;
    SignMethodCalculation: number;
    SignCalculationObject: number;
    MeasureOfQuantity: number;
  };
}

const getOrderReceiptItems = (order?: Order | null) => {
  const goods =
    (order?.realizations
      ?.map(realization =>
        realization.elements?.map(realizationElement => ({
          Register: {
            Name: `${realizationElement.orderElement?.stockItem?.id} ${realizationElement.orderElement?.stockItem?.itemNameWithPosition}`.slice(
              0,
              64,
            ),
            Quantity: realizationElement.quantity,
            Price:
              realizationElement.offerPrice &&
              realizationElement.offerPrice / 100,
            Amount:
              realizationElement.totalCost &&
              realizationElement.totalCost / 100,
            Department: 0,
            Tax: -1,
            SignMethodCalculation: 4, //"ПОЛНЫЙ РАСЧЕТ (Полная оплата, в том числе с учетом аванса в момент передачи предмета расчета)"
            SignCalculationObject: 1, //"ТОВАР (наименование и иные сведения, описывающие товар)"
            MeasureOfQuantity: 0,
          },
        })),
      )
      ?.flat() as ReceiptElement[]) || [];
  const services =
    ((order?.delivery?.cost && [
      {
        Register: {
          Name: 'Доставка',
          Quantity: 1,
          Price: order?.delivery?.cost && order?.delivery?.cost / 100,
          Amount: order?.delivery?.cost && order?.delivery?.cost / 100,
          Department: 0,
          Tax: -1,
          SignMethodCalculation: 4, //"ПОЛНЫЙ РАСЧЕТ (Полная оплата, в том числе с учетом аванса в момент передачи предмета расчета)"
          SignCalculationObject: 4, //"УСЛУГА (наименование и иные сведения, описывающие услугу)"
          MeasureOfQuantity: 0,
        },
      },
    ]) as ReceiptElement[]) || [];
  return [...goods, ...services];
};

export const RegisterReceiptForm: FC<{
  order?: Order | null;
  inn?: string | number;
}> = ({ order: providedOrder }) => {
  const orderRequest = useGetOrder({
    variables: { orderId: providedOrder?.id as number },
    skip: !providedOrder,
  });

  const order = orderRequest.data;

  const inn = order?.contractor?.inn as string;

  useEffect(() => {
    handleState({ CashierVATIN: inn });
  }, [inn]);

  const [state, setState] = useState<{
    [key: string]: null | boolean | string | number | void;
  }>({
    IsFiscalCheck: true,
    TypeCheck: 0,
    NotPrint: false,
    NumberCopies: 0,
    CashierName: 'Бурый С. С.',
    CashierVATIN: inn,
    ClientAddress: null,
    ClientInfo: null,
    ClientINN: null,
    SenderEmail: null,
    AddressSettle: null,
    PlaceMarket: null,
    TaxVariant: 5,
    PayByProcessing: false,
    NumDeviceByProcessing: null,
    ReceiptNumber: null,
    PrintSlipAfterCheck: false,
    PrintSlipForCashier: false,
    RRNCode: null,
    AuthorizationCode: null,
  });

  let Payment = 0;

  let CheckStrings: ReceiptElement[] = [];

  if (order) {
    CheckStrings = getOrderReceiptItems(order);
    Payment =
      CheckStrings?.reduce((prev, curr) => prev + curr?.Register.Amount, 0) ||
      0;
  }

  const [hints, showHints] = useState(false);

  const [result, setResult] = useState<null | { Error: string }>(null);

  const requestCashRegister = useGetCashRegister({
    variables: { inn },
    skip: !inn,
  });

  const handleState = (next: any) => {
    setState(prev => ({ ...prev, ...next }));
  };

  const handleRegister = () => {
    setResult(null);
    console.log(requestCashRegister.data);
    if (requestCashRegister.data?.Info?.InnOrganization) {
      registerCheck({
        InnKkm: requestCashRegister.data?.Info?.InnOrganization,
        ...state,
        CheckStrings,
        Cash: Payment,
        ElectronicPayment: 0,
        AdvancePayment: 0,
        Credit: 0,
        CashProvision: 0,
      }).then(res => {
        setResult(res.data);
      });
    }
  };

  if (!requestCashRegister.data) return null;

  if (
    !requestCashRegister.loading &&
    requestCashRegister.data?.Info?.InnOrganization !== String(inn)
  ) {
    return <Alert severity="error">Не удалось определить кассу</Alert>;
  }

  return (
    <Grid container>
      {requestCashRegister.data?.Info?.SessionState &&
        requestCashRegister.data?.Info?.SessionState !==
          CashRegisterSessionState.Открыта && (
          <Grid item xs={12}>
            <Alert severity="info">
              Смена{' '}
              {
                CashRegisterSessionState[
                  requestCashRegister.data?.Info?.SessionState
                ]
              }
            </Alert>
          </Grid>
        )}
      <Grid item xs={12}>
        <FormControlLabel
          control={<Checkbox checked={hints} />}
          onChange={() => showHints(!hints)}
          label="Показать подсказки"
        />
      </Grid>
      <Grid item xs={12}>
        {ReceiptFormProps.map(item => (
          <Box mt={2} key={item.key}>
            <ReceiptProp
              onChange={handleState}
              value={state?.[item.key]}
              prop={item}
            />
            {hints && hintsMap?.[item.key] && (
              <Box mt={1}>
                <Typography variant="caption">{hintsMap[item.key]}</Typography>
              </Box>
            )}
            <Box mt={2}>
              <Divider />
            </Box>
          </Box>
        ))}
        <Box mt={2}>
          {CheckStrings.map(item => (
            <Box key={item.Register.Name}>
              {item.Register.Name} {item.Register.Quantity}шт{' '}
              {item.Register.Price}₽
            </Box>
          ))}
        </Box>
        {result?.Error && (
          <Box mt={2} display="flex">
            <Alert severity="error">{result.Error}</Alert>
          </Box>
        )}
        <Box mt={2}>
          <Button onClick={handleRegister} variant="contained">
            Зарегистрировать чек
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export const RegisterReceiptDialog = ({ order }: any) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)}>Чек</Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <RegisterReceiptForm order={order} />
        </DialogContent>
      </Dialog>
    </>
  );
};
