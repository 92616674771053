import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Fab,
  FormControlLabel,
  Theme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useRouter } from 'src/hooks/useRouter';

import { WithLoading } from './common/with-loading';
import { CustomerSelect } from './CustomerSelect';
import { VehicleSelect } from './vehicle-select';
import { VehicleCreate } from './vehicle-create';
import { createDiagnostic } from 'src/entities/diagnostic';
import { Contractor, Customer, Diagnostics, Vehicle } from 'src/shared/api';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'DiagnosticCreate' },
);

export const DiagnosticCreate: React.FunctionComponent<typeProps> = () => {
  const classes = useStyles({});
  const { push } = useRouter();

  const [dialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const [vehicle, setVehicle] = useState<Vehicle | null | void>(null);
  const [phone, setPhone] = useState('');
  const [customer, setCustomer] = useState<Customer | null>(null);

  const handleCreate = () => {
    setLoading(true);
    createDiagnostic({
      customer: customer ? { id: customer.id } : null,
      vehicle: vehicle ? { id: vehicle.id } : null,
      contractor: {
        id: 2,
      },
    }).then((diagnostic: any) => {
      push(`/diagnostics/${diagnostic.id}`);
    });
  };

  const disabled = Boolean(loading);

  return (
    <div className={classes.root}>
      <Fab color="primary" size="small" onClick={() => setDialog(true)}>
        <Add />
      </Fab>
      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <CustomerSelect
            phone={phone}
            onChange={setPhone}
            onSelect={setCustomer}
            customer={customer}
          />
          <Box mt={2}>
            <VehicleSelect
              onChange={value => setVehicle(value)}
              customer={customer}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialog(false)}>Закрыть</Button>
          <WithLoading loading={loading}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreate}
              disabled={disabled}
            >
              Создать
            </Button>
          </WithLoading>
        </DialogActions>
      </Dialog>
    </div>
  );
};
