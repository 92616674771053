import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

interface typeProps {}

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {},
    }),
  { name: 'IndexPage' },
);

export const IndexPage: React.FunctionComponent<typeProps> = ({}) => {
  const classes = useStyles({});

  return <div className={classes.root}></div>;
};
