import { useRequest } from 'src/hooks/useRequest';
import { request } from 'src/services/request';
import { CashRegister, CashRegisterDetailInfo, KkmDevice, TypeCheck } from 'src/shared/api';

export function useGetKkmDevices({
  variables,
  skip,
}: {
  variables?: {};
  skip?: boolean;
}) {
  return useRequest<
    {
      Error: string;
      ListUnit: Array<KkmDevice>;
    },
    typeof variables
  >({
    url: `/kkm/devices`,
    method: 'get',
    skip,
  });
}

export function stopDevices() {
  return request.post(`/kkm/devices/stop`, {});
}
export function startDevices() {
  return request.post(`/kkm/devices/start`, {});
}

export function openShift() {
  return request.post(`/kkm/open_shift`, {});
}
export function closeShift() {
  return request.post(`/kkm/close_shift`, {});
}

export function useGetKkt({
  variables,
  skip,
}: {
  variables: { deviceNumber: number };
  skip?: boolean;
}) {
  return useRequest<
    {
      Error: string;
      Info: CashRegister;
    },
    typeof variables
  >({
    url: `/kkm/devices/${variables.deviceNumber}`,
    method: 'get',
    skip,
  });
}

export function useGetCashRegister({
  variables,
  skip,
}: {
  variables: { inn: number | string };
  skip?: boolean;
}) {
  return useRequest<
    {
      Error: string;
      Info: CashRegisterDetailInfo;
    },
    typeof variables
  >({
    url: `/kkm/devices/cash_register?inn=${variables.inn}`,
    method: 'get',
    skip,
  });
}


export function registerCheck(data: any) {
  const withoutEmptyProps = Object.entries(data).reduce((prev, curr) => {
    let next = prev;
    if (curr[1] === null) {
      return next;
    }

    if (typeof curr[1] === 'string' && curr[1].trim().length > 0) {
      next = { ...next, [curr[0]]: curr[1].trim() };
    }
    return { ...next, [curr[0]]: curr[1] };
  }, {});

  return request.post(`/kkm/register_check`, withoutEmptyProps);
}
